*,
html {
  box-sizing: border-box;
}

body {
  font-family: 'Recursive';
  font-size: 13px;
}

span[role='label'] {
  user-select: none;
}

.app {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
